const setupButton = (button) => {
  button.addEventListener('click', (event) => {
    const goalsInput = button.parentNode.querySelector('input.goals-to-trigger');
    const goals = goalsInput.getAttribute('data-goals');

    if (goals) {
      fetch('/api/sitecore/goals/TriggerGoals', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ Goals: goals.split('|') }),
      });
    }
  });
};

document.addEventListener('DOMContentLoaded', function () {
  if (_THULEDATA.IsEditing) {
    return;
  }

  const buttons = document.querySelectorAll('.custom-btn');
  buttons?.forEach((button) => {
    setupButton(button);
  });
});
